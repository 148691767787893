import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "white",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M17.6569 17.6569L15.6593 17.6745L15.6593 9.75493L7.05025 18.364L5.63604 16.9497L14.2451 8.34072L6.32547 8.34072L6.34314 6.34314L17.6569 6.34314L17.6569 17.6569Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }